import { inject } from 'vue';

export const VAR_DARK = 'var-dark';
export const VAR_CREME = 'var-creme';

export const themes = {
  THEME_BRAND: 'theme-brand',
  THEME_LIGHT: 'theme-light',
  THEME_DARK: 'theme-dark',
  THEME_ALTERNATIVE: 'theme-alternative',
  THEME_CORPORATE: 'theme-corporate',
  THEME_VENUE: 'theme-corporate-venue',
};

export const variantedComponents = {
  CONTACT_US: 'CONTACT_US',
  MAIN_HEADER: 'MAIN_HEADER',
  MOBILE_MENU: 'MOBILE_MENU',
  INTRODUCTION_IMAGE: 'INTRODUCTION_IMAGE',
  INTRODUCTION_ITEM: 'INTRODUCTION_ITEM',
  PUB_INFO_BAR: 'PUB_INFO_BAR',
  PROMO_CAROUSEL_ITEM: 'PROMO_CAROUSEL_ITEM',
  SIGNUP_BANNER: 'SIGNUP_BANNER',
  OPENING_HOURS: 'OPENING_HOURS',
  FORM_CONTAINER: 'FORM_CONTAINER',
  SIGNUP_BANNER_MODAL: 'SIGNUP_BANNER_MODAL',
  DOWNLOAD_LINKS_MODAL: 'DOWNLOAD_LINKS_MODAL',
  FACILITIES_BLOCK: 'FACILITIES_BLOCK',
  MENUS_DOWNLOAD: 'MENUS_DOWNLOAD',
  FIXED_PROMO_MESSAGE: 'FIXED_PROMO_MESSAGE',
  MAIN_FOOTER: 'MAIN_FOOTER',
  PROMO_LIST: 'PROMO_LIST',
  HERO_CAROUSEL: 'HERO_CAROUSEL',
  EVENTS_NAVIGATION: 'EVENTS_NAVIGATION',
};

const brands = {
  CRAFTED: 'Crafted',
  METRO: 'Metro',
  GREENE_KING: 'GreeneKing',
  HUNGRY_HORSE: 'HungryHorse',
  FARMHOUSE_INNS: 'FarmhouseInns',
  CHEF_BREWER: 'ChefBrewer',
  WACKY_WAREHOUSE: 'WackyWarehouse',
  GREENE_KING_INNS: 'GreeneKingInns',
  BELHAVEN: 'Belhaven',
  SEARED: 'Seared',
  EVERLY: 'Everly',
  FARMHOUSE_KITCHEN: 'FarmhouseKitchen',
  KINSMITH: 'Kinsmith',
  HIGH_TALES: 'HighTales',
  PUB_PARTNERS: 'PubPartners',
};

export const componentDarkVariantMap = {
  [variantedComponents.INTRODUCTION_IMAGE]: [brands.BELHAVEN],
  [variantedComponents.INTRODUCTION_ITEM]: [brands.BELHAVEN],
  [variantedComponents.PUB_INFO_BAR]: [
    brands.GREENE_KING,
    brands.FARMHOUSE_INNS,
    brands.GREENE_KING_INNS,
  ],
  [variantedComponents.PROMO_CAROUSEL_ITEM]: [brands.CRAFTED, brands.HUNGRY_HORSE],
  [variantedComponents.SIGNUP_BANNER]: [
    brands.FARMHOUSE_INNS,
    brands.WACKY_WAREHOUSE,
    brands.BELHAVEN,
    brands.PUB_PARTNERS,
  ],
  [variantedComponents.SIGNUP_BANNER_MODAL]: [
    brands.CRAFTED,
    brands.GREENE_KING,
    brands.FARMHOUSE_INNS,
    brands.CHEF_BREWER,
    brands.GREENE_KING_INNS,
  ],
  [variantedComponents.DOWNLOAD_LINKS_MODAL]: [brands.CRAFTED, brands.GREENE_KING],
  [variantedComponents.OPENING_HOURS]: [
    brands.CRAFTED,
    brands.GREENE_KING,
    brands.WACKY_WAREHOUSE,
    brands.METRO,
    brands.FARMHOUSE_INNS,
    brands.CHEF_BREWER,
    brands.GREENE_KING_INNS,
    brands.BELHAVEN,
    brands.EVERLY,
    brands.FARMHOUSE_KITCHEN,
    brands.KINSMITH,
    brands.PUB_PARTNERS,
  ],
  [variantedComponents.FACILITIES_BLOCK]: [brands.BELHAVEN],
  [variantedComponents.MENUS_DOWNLOAD]: [brands.BELHAVEN],
  [variantedComponents.FIXED_PROMO_MESSAGE]: [brands.CRAFTED],
  [variantedComponents.MAIN_FOOTER]: [
    brands.CRAFTED,
    brands.METRO,
    brands.GREENE_KING,
    brands.HUNGRY_HORSE,
    brands.FARMHOUSE_INNS,
    brands.CHEF_BREWER,
    brands.WACKY_WAREHOUSE,
    brands.BELHAVEN,
    brands.GREENE_KING_INNS,
    brands.KINSMITH,
    brands.HIGH_TALES,
    brands.PUB_PARTNERS,
  ],
  [variantedComponents.HERO_CAROUSEL]: [
    brands.CRAFTED,
    brands.METRO,
    brands.GREENE_KING,
    brands.HUNGRY_HORSE,
    brands.FARMHOUSE_INNS,
    brands.CHEF_BREWER,
    brands.WACKY_WAREHOUSE,
    brands.BELHAVEN,
    brands.GREENE_KING_INNS,
    brands.EVERLY,
    brands.FARMHOUSE_KITCHEN,
    brands.KINSMITH,
    brands.HIGH_TALES,
    brands.PUB_PARTNERS,
  ],
  [variantedComponents.MAIN_HEADER]: [brands.PUB_PARTNERS],
  [variantedComponents.MOBILE_MENU]: [brands.PUB_PARTNERS],
};

export const componentCremeVariantMap = {
  [variantedComponents.FORM_CONTAINER]: [brands.GREENE_KING],
  [variantedComponents.CONTACT_US]: [brands.CHEF_BREWER, brands.WACKY_WAREHOUSE],
  [variantedComponents.EVENTS_NAVIGATION]: [
    brands.CRAFTED,
    brands.GREENE_KING,
    brands.GREENE_KING_INNS,
  ],
};

export const useDetectVariantByBrand = () => {
  const jssStore = inject('jssStore');
  const brandName = jssStore?.sitecoreContext()?.brandName || null;

  return (component) => ({
    [VAR_DARK]: componentDarkVariantMap[component]?.includes(brandName),
    [VAR_CREME]: componentCremeVariantMap[component]?.includes(brandName),
  });
};

export const componentVariantByThemeMap = {
  [variantedComponents.MAIN_HEADER]: {
    [themes.THEME_BRAND]: [brands.METRO],
    [themes.THEME_DARK]: [brands.METRO],
    [themes.THEME_ALTERNATIVE]: [brands.METRO],
    [themes.THEME_CORPORATE]: [
      brands.GREENE_KING,
      brands.HUNGRY_HORSE,
      brands.FARMHOUSE_INNS,
      brands.CHEF_BREWER,
      brands.WACKY_WAREHOUSE,
      brands.GREENE_KING_INNS,
      brands.BELHAVEN,
    ],
  },
  [variantedComponents.MOBILE_MENU]: {
    [themes.THEME_BRAND]: [brands.METRO],
    [themes.THEME_DARK]: [brands.METRO],
    [themes.THEME_ALTERNATIVE]: [brands.METRO],
    [themes.THEME_CORPORATE]: [
      brands.GREENE_KING,
      brands.FARMHOUSE_INNS,
      brands.WACKY_WAREHOUSE,
      brands.GREENE_KING_INNS,
      brands.BELHAVEN,
    ],
  },
  [variantedComponents.PROMO_CAROUSEL_ITEM]: {
    [themes.THEME_CORPORATE]: [brands.BELHAVEN],
  },
  [variantedComponents.FIXED_PROMO_MESSAGE]: {
    [themes.THEME_BRAND]: [brands.METRO, brands.GREENE_KING, brands.GREENE_KING_INNS],
    [themes.THEME_DARK]: [brands.METRO],
    [themes.THEME_ALTERNATIVE]: [brands.METRO],
    [themes.THEME_VENUE]: [
      brands.GREENE_KING,
      brands.CHEF_BREWER,
      brands.WACKY_WAREHOUSE,
      brands.GREENE_KING_INNS,
      brands.BELHAVEN,
      brands.HUNGRY_HORSE,
    ],
  },
  [variantedComponents.PROMO_LIST]: {
    [themes.THEME_VENUE]: [brands.FARMHOUSE_INNS],
  },
};

export const useDetectVariantByTheme = () => {
  const jssStore = inject('jssStore');
  const theme = jssStore?.sitecoreContext()?.theme || null;
  const brandName = jssStore?.sitecoreContext()?.brandName || null;

  return (component) => ({
    [VAR_DARK]: componentVariantByThemeMap[component]?.[theme]?.includes(brandName),
  });
};

export const useDetectVariantByBrandAndTheme = () => {
  const jssStore = inject('jssStore');
  const brandName = jssStore?.sitecoreContext()?.brandName || null;
  const theme = jssStore?.sitecoreContext()?.theme || null;

  return (component) => ({
    [VAR_DARK]:
      componentDarkVariantMap[component]?.includes(brandName) ||
      componentVariantByThemeMap[component]?.[theme]?.includes(brandName),
    [VAR_CREME]: componentCremeVariantMap[component]?.includes(brandName),
  });
};
