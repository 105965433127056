<template>
  <section class="introduction-item show-on-load" :class="mainClasses">
    <div class="container">
      <div class="row">
        <div
          class="introduction-item__texts-wrapper col-12 col-md-6"
          :class="[isOneOpeningHoursItem ? 'col-lg-6 col-xl-7' : 'col-lg-5']"
        >
          <div class="introduction-item__texts">
            <sc-text
              class="introduction-item__header"
              :class="headerClasses"
              tag="h2"
              :field="fields.title"
            />
            <p class="introduction-item__description">
              <span>
                {{ introductionItemDescription }}
              </span>
              <button
                v-if="showReadMore"
                :aria-expanded="isShowMoreOpened"
                class="introduction-item__description-more link"
                @click="handleClickShowMore"
              >
                {{ isShowMoreOpened ? t('introduction-show-less') : t('introduction-show-more') }}
              </button>
            </p>

            <div v-if="cta1Text || cta2Text" class="introduction-item__buttons">
              <base-button
                v-if="cta1Text"
                data-test-id="introduction-cta1"
                :label="cta1Text"
                type="link"
                :link="fields.cta1.value"
                variant="primary"
                @click="handleActionClick(cta1Text)"
              />
              <base-button
                v-if="cta2Text"
                data-test-id="introduction-cta2"
                :label="cta2Text"
                type="link"
                :link="fields.cta2.value"
                variant="secondary"
                @click="handleActionClick(cta2Text)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="openingHoursVisible"
          class="col-12 col-md-6"
          :class="[isOneOpeningHoursItem ? 'col-lg-6 col-xl-5' : 'col-lg-7']"
        >
          <div
            class="introduction-item__opening-hours"
            :class="{
              'introduction-item__opening-hours--single-item': isOneOpeningHoursItem,
              ...detectComponentVariant(variantedComponents.OPENING_HOURS),
            }"
          >
            <slot name="admissionTypes" />
            <opening-hours
              v-if="fields?.openingHours?.value?.length"
              :title="dictionary.openingHours"
              :icon="'Clock'"
              :opening-hours="fields.openingHours"
              :description="openingHoursDescription"
              :first-bolded="!fields.enableSpecialHoursAdvancedNotice?.value"
            />
            <opening-hours
              v-if="fields?.servingHours?.value?.length"
              :title="dictionary.servingHours"
              :icon="'Cutlry'"
              :opening-hours="fields.servingHours"
              :first-bolded="!fields.enableSpecialHoursAdvancedNotice?.value"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import BaseButton from '../2_elements/BaseButton/BaseButton.vue';
import OpeningHours from '../2_elements/OpeningHoursItem/OpeningHoursItem.vue';
import { useI18n } from 'vue-i18n';
import { useRegisterDataLayerAction } from '../../data-layer/helpers/registerDataLayer';
import { contentButtonsDataLayer } from '../../data-layer/contentButtons';
import { DEFAULT_DATA_LAYER } from '../../data-layer/helpers/defaultProps';
import { useDetectVariantByBrand, variantedComponents } from '../../utils/componentVariants';

const detectComponentVariant = useDetectVariantByBrand();
const { t } = useI18n();

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
  showOpeningHours: {
    type: Boolean,
    default: false,
  },
  openingHoursDescription: {
    type: String,
  },
  admission: {
    type: Boolean,
    default: false,
  },
});

const DESCRIPTION_MAX_VISIBLE_LENGTH = 260;

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName || null;

const isShowMoreOpened = ref(false);
const showReadMore = ref(props.fields.description?.value?.length > DESCRIPTION_MAX_VISIBLE_LENGTH);

const mainClasses = computed(() => ({
  'introduction-item--only-texts': !openingHoursVisible.value,
  'bottom-paper-border': brandName === 'ChefBrewer',
  ...detectComponentVariant(variantedComponents.INTRODUCTION_ITEM),
}));

const headerClasses = computed(() => [brandName === 'Belhaven' ? 'font-xl' : 'font-l']);
const cta1Text = computed(() => props.fields.cta1?.value?.text);
const cta2Text = computed(() => props.fields.cta2?.value?.text);

const introductionItemDescription = computed(() =>
  !isShowMoreOpened.value && showReadMore.value
    ? `${props.fields.description?.value?.slice(0, DESCRIPTION_MAX_VISIBLE_LENGTH)}...`
    : props.fields.description?.value
);

const dictionary = computed(() => {
  const specialHrs = props.fields.enableSpecialHoursAdvancedNotice?.value;
  return {
    openingHours: t(
      specialHrs ? 'special-hours-advanced-notice-festive-opening-hours' : 'opening-hours'
    ),
    servingHours: t(specialHrs ? 'special-hours-advanced-notice-kitchen-hours' : 'serving-hours'),
  };
});

const handleClickShowMore = () => {
  handleActionClick(
    !isShowMoreOpened.value
      ? contentButtonsDataLayer.actions.SHOW_MORE
      : contentButtonsDataLayer.actions.SHOW_LESS
  );
  isShowMoreOpened.value = !isShowMoreOpened.value;
};

const openingHoursVisible = computed(
  () =>
    props.fields.openingHours?.value.length ||
    props.fields.servingHours?.value.length ||
    props.showOpeningHours ||
    props.admission
);

const isOneOpeningHoursItem = computed(
  () =>
    !props.admission &&
    (!props.fields.openingHours?.value?.length || !props.fields.servingHours?.value?.length)
);

const registerDataLayerAction = useRegisterDataLayerAction(DEFAULT_DATA_LAYER);

const handleActionClick = (action) => {
  registerDataLayerAction({
    event_name: 'click_button',
    link_text: action,
    intent: action,
    component: 'introduction_item',
  });
};
</script>

<style lang="scss">
@import './scss/IntroductionItem.scss';
</style>
